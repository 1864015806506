













































































































































































































































































































































































































.framePage-halfBody {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.framePage-halfBody .framePage-article {
  background-color: #fff;
  margin-right: 0.675rem;
  height: 100%;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
  width: 25%;
  min-width: 20rem;
}
.formBox-seat {
  width: 70%;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
}
.formBox-seat .itembox {
  display: flex;
}
.formBox-seat .itembox > label {
  width: 6rem;
}
.formBox-seat .el-form-item {
  margin-bottom: 15px;
}
.formBox-seat .itembox .el-form-item__content {
  flex: 1;
}
.formBox-seat .itembox .el-form-item__content .el-select {
  width: 100%;
}
.createdCourse {
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 240px;
    font-size: 14px;
  }
  .level {
    padding: 3px;
    border-radius: 6px;
    color: #333;
    background-color: #e0f2ff;
    min-width: 20px;
    text-align: center;
  }
}
